* {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.info-container {
  margin-top: 1.428em;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.logo-divider {
  margin-top: 1.214em;
  margin-bottom: 1.428em;
  border: 0;
  border-top: 1px solid #eee;
}

.application-box {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 1.2857em;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  text-align: center;
  background-image: linear-gradient(to bottom, #e8e8e8 0, #f5f5f5 100%);
  background-repeat: repeat-x;
  border-color: #dcdcdc;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05),
    0 1px 0 rgba(255, 255, 255, 0.1);
}

.application-box-title {
  font-weight: 500;
  line-height: 1.1;
  font-size: 36px;
  margin-top: 0.555em;
  margin-bottom: 0.4em;
}

.login-title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0.933em;
}

.login-subtitle {
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  margin-bottom: 0.714em;
}

.connect-to-quickbooks-button-image {
  width: 178px;
}

.connect-to-quickbooks-button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.register-modal-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-modal-content-container {
  position: relative;
}

.register-modal-close-button {
  position: absolute;
  top: 20px;
  left: calc(50%);
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.register-modal-content {
  border: none;
  background-color: white;
  border-radius: 20px;
  min-width: 600px;
  min-height: 600px;
}

@media (min-width: 768px) {
  .info-container {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .info-container {
    width: 940px;
  }
}

@media (min-width: 1200px) {
  .info-container {
    width: 1140px;
  }
}
